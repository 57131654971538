<template>
  <svg
    id="Path_2"
    data-name="Path 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 8.615 5.743"
    :width="width"
    :height="height"
  >
    <path id="Path_2-2" data-name="Path 2" d="M4.307,0,0,5.743H8.615Z" fill="#bdbec2" />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '13.563',
    },
    width: {
      type: String,
      default: '13.56',
    },
    color: {
      type: String,
      default: 'label-text',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
